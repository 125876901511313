import { VirtualElement } from "@floating-ui/react";
import { RefObject } from "react";

import { useContextMenuListener } from "../baseContextMenu/useContextMenuListener";

export function useGlobalActionContextMenuFromActionSheet(
  element: RefObject<HTMLElement | unknown> | null,
  openWithAnchor: (anchor: VirtualElement) => void,
) {
  useContextMenuListener(element, openWithAnchor);
}
