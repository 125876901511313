import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { type CSSProperties, useMemo } from "react";

import { DraggableRow, type DraggableRowProps } from "./draggableRow";
import { ORDERABLE_LIST_ANIMATION_DURATION_MS } from "./orderableList.constants";
import type {
  OrderableListRow,
  TransitionDirection,
} from "./orderableList.types";
import { useDraggingState } from "./useDraggingState";

type AnimatedDraggableRowProps<T extends OrderableListRow> =
  DraggableRowProps<T> & {
    direction: TransitionDirection | undefined;
    draggedRowHeight: number | undefined;
  };

const AnimatedDraggableRow_ = <T extends OrderableListRow>({
  item,
  Renderer,
  containerClassName,
  withHandle,
  isDraggingOverList,
  direction,
  draggedRowHeight,
  targetType,
}: AnimatedDraggableRowProps<T>) => {
  const classes = useStyles();

  const isDragging = useDraggingState();

  const containerStyle = useMemo<CSSProperties | undefined>(() => {
    if (!isDraggingOverList || draggedRowHeight === undefined) {
      return undefined;
    }

    return {
      transform:
        direction === "up"
          ? `translateY(-${draggedRowHeight}px)`
          : direction === "down"
            ? `translateY(${draggedRowHeight}px)`
            : undefined,
    };
  }, [direction, draggedRowHeight, isDraggingOverList]);

  return (
    <div
      className={clsx(isDragging && classes.withTransition)}
      style={containerStyle}
    >
      <DraggableRow<T>
        item={item}
        Renderer={Renderer}
        containerClassName={containerClassName}
        withHandle={withHandle}
        targetType={targetType}
        isDraggingOverList={isDraggingOverList}
      />
    </div>
  );
};

const useStyles = makeStyles({
  withTransition: {
    transition: `transform ${ORDERABLE_LIST_ANIMATION_DURATION_MS}ms`,
  },
});

export const AnimatedDraggableRow = React.memo(
  AnimatedDraggableRow_,
) as typeof AnimatedDraggableRow_;
