import { type Ref, type RefAttributes, useCallback, useRef } from "react";
import {
  Animated,
  Easing,
  type NativeSyntheticEvent,
  StyleSheet,
  type TextInputFocusEventData,
} from "react-native";

import { betterForwardRef } from "@kraaft/shared/core/utils/betterForwardRef";

import { ColorStyle } from "../../constants/color";
import { getNativeDriver } from "../../utils/animation.utils";
import { webOnlyStyle } from "../../utils/style.utils";
import type { WithTextInputComponent } from "../textInput/textInput.types";
import { BaseTextInputComponent } from "../textInputComponents";
import type {
  CompactTextInputHandle,
  CompactTextInputProps,
  RequiredTextInputComponentHandle,
  RequiredTextInputComponentProps,
} from "./compactTextInput.types";

const ANIMATION_TIME = 200;
const ANIMATION_EASING = Easing.inOut(Easing.ease);

export const CompactTextInput = betterForwardRef(
  <
    P extends RequiredTextInputComponentProps &
      RefAttributes<RequiredTextInputComponentHandle>,
  >(
    {
      TextInputComponent: _TextInputComponent,
      hasError,
      onFocus,
      onBlur,
      autoFocus,
      extraTextInputProps,
      ...props
    }: CompactTextInputProps &
      WithTextInputComponent<
        RequiredTextInputComponentProps,
        RequiredTextInputComponentHandle,
        P
      >,
    externalRef: Ref<CompactTextInputHandle>,
  ) => {
    const colorAnimation = useRef(
      new Animated.Value(autoFocus ? 1 : 0),
    ).current;

    const inactiveBorderColor =
      (hasError && ColorStyle.ACTION_DESCTRUCTIVE_DISABLED) ||
      ColorStyle.BACKGROUND_STANDARD;

    const handleFocus = useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        Animated.timing(colorAnimation, {
          toValue: 1,
          duration: ANIMATION_TIME,
          easing: ANIMATION_EASING,
          ...getNativeDriver(false),
        }).start();

        onFocus?.(event);
      },
      [colorAnimation, onFocus],
    );

    const handleBlur = useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        Animated.timing(colorAnimation, {
          toValue: 0,
          duration: ANIMATION_TIME,
          easing: ANIMATION_EASING,
          ...getNativeDriver(false),
        }).start();

        onBlur?.(event);
      },
      [colorAnimation, onBlur],
    );

    const borderColor = colorAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: [inactiveBorderColor, ColorStyle.ACTION_CTA],
    });

    const TextInputComponent =
      _TextInputComponent ?? BaseTextInputComponent.TextInputWithAutoSize;

    return (
      <Animated.View style={[styles.container, { borderColor }]}>
        <TextInputComponent
          ref={externalRef}
          {...props}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholderTextColor={ColorStyle.FONT_LOW_EMPHASIS}
          {...(extraTextInputProps as any)}
        />
      </Animated.View>
    );
  },
);

export const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    borderWidth: 1,
    ...webOnlyStyle({ cursor: "text" }),
    overflow: "hidden",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    padding: 8,
  },
});
