import {
  ExtendedRefs,
  FloatingContext,
  FloatingFocusManager,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  ReferenceType,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react";
import { makeStyles } from "@mui/styles";
import { clsx } from "clsx";
import { CSSProperties, ReactNode, useCallback, useRef } from "react";

import { Spacing } from "../../../constants";
import type { PopupSheetDefinition } from "./popupSheet.definition/popupSheet.definition.types";

const centeredPopupStyle = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const ANIMATION_DURATION = 150;

export const PopupSize = {
  MEDIUM: 960,
  SMALL: 500,
};
export type PopupSize = keyof typeof PopupSize;

export const PopupSheetHost: PopupSheetDefinition["Host"] = ({
  id = "ide2e-popup",
  children,
  open,
  size,
  onClose,
}) => {
  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        onClose();
      }
    },
    [onClose],
  );

  const nodeId = useFloatingNodeId();
  const { context, refs, floatingStyles } = useFloating({
    open,
    nodeId,
    onOpenChange: handleOpenChange,
  });

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: ANIMATION_DURATION,
    initial: {
      transform: "scale(0.75)",
      opacity: 0,
    },
    common: {
      transformOrigin: "center",
      transform: "scale(1)",
      opacity: 1,
    },
  });

  return (
    <FloatingNode id={nodeId}>
      {isMounted ? (
        <MountedPopupSheetHost
          open={open}
          context={context}
          floatingStyles={floatingStyles}
          refs={refs}
          styles={styles}
          size={size}
          id={id}
        >
          {children}
        </MountedPopupSheetHost>
      ) : null}
    </FloatingNode>
  );
};

const useStyles = makeStyles({
  overlay: {
    zIndex: 1500, // above legacy dialogs
    display: "flex",
    backgroundColor: "rgba(0,0,0,.25)",
  },

  overlayFadeIn: {
    animation: "$fadeIn",
    animationDuration: `${ANIMATION_DURATION}ms`,
    animationFillMode: "forwards",
    animationTimingFunction: "ease",
  },

  overlayFadeOut: {
    animation: "$fadeOut",
    animationDuration: `${ANIMATION_DURATION}ms`,
    animationFillMode: "forwards",
    animationTimingFunction: "ease",
  },

  "@global": {
    "@keyframes fadeIn": {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },

    "@keyframes fadeOut": {
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
    },
  },
});

const MountedPopupSheetHost = ({
  open,
  context,
  floatingStyles,
  refs,
  styles,
  size,
  id,
  children,
}: {
  open: boolean;
  context: FloatingContext<ReferenceType>;
  floatingStyles: CSSProperties;
  refs: ExtendedRefs<ReferenceType>;
  styles: CSSProperties;
  size: PopupSize;
  id: string;
  children: ReactNode;
}) => {
  const classes = useStyles();

  const dismiss = useDismiss(context, {
    bubbles: false, // allows the sheet to be dismissed without dismissing the parent. Ex: database filter menu
  });

  const { getFloatingProps } = useInteractions([dismiss]);

  // focus the first input in the popup
  const parentRef = useRef<HTMLDivElement>(null);
  const initialFocus = useRef<HTMLInputElement | null>(null);
  initialFocus.current =
    initialFocus.current ?? parentRef.current?.querySelector("input") ?? null;

  return (
    <FloatingPortal>
      <FloatingOverlay
        className={clsx(
          classes.overlay,
          open ? classes.overlayFadeIn : classes.overlayFadeOut,
        )}
        lockScroll
      >
        <FloatingFocusManager
          context={context}
          closeOnFocusOut={true}
          visuallyHiddenDismiss
          initialFocus={initialFocus}
        >
          <div
            style={{ ...floatingStyles, ...centeredPopupStyle }}
            ref={refs.setFloating}
            {...getFloatingProps()}
          >
            <div
              style={{
                ...styles,
                width: PopupSize[size],
                maxWidth: `calc(100vw - ${Spacing.S24}px)`,
              }}
              id={id}
              ref={parentRef}
            >
              {children}
            </div>
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};
