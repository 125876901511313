import { useMemo } from "react";

import type { EdgeInsets } from "../useSafeAreaInsets/useSafeAreaInsets.types";

export function useMaxSafeAreaInsets(maxs: Partial<EdgeInsets>) {
  return useMemo(
    () => ({
      top: maxs.top ?? 0,
      bottom: maxs.bottom ?? 0,
      left: maxs.left ?? 0,
      right: maxs.right ?? 0,
    }),
    [maxs.bottom, maxs.left, maxs.right, maxs.top],
  );
}
