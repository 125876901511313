import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  offset,
  Rect,
  shift,
  size,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import {
  Component,
  type MouseEventHandler,
  useCallback,
  useLayoutEffect,
} from "react";

import { isPlacementHorizontal } from "@kraaft/helper-functions";

import { Spacing } from "../../../constants";
import { getSpacingFromSizeValue } from "../../../utils";
import type {
  AnchoredSheetDefinition,
  OffsetSpacing,
} from "./anchoredSheet.definition/anchoredSheet.definition.types";

const ANCHORED_SHEET_TRANSITION_DURATION = 150;

function getScale(value: number) {
  return `scale(${value}, ${value ** 2})`;
}

function calculateRelativePosition(refRect: Rect, elementRect: Rect) {
  const deltaX = refRect.x - elementRect.x;
  const deltaY = refRect.y - elementRect.y;

  const percentageX = deltaX / elementRect.width;
  const percentageY = deltaY / elementRect.height;

  return {
    x: percentageX,
    y: percentageY,
  };
}

function getMainAxisSpacingFromOffset(offsetSpacing: OffsetSpacing) {
  return typeof offsetSpacing === "object" ? offsetSpacing.main : offsetSpacing;
}

function getCrossAxisSpacingFromOffset(offsetSpacing: OffsetSpacing) {
  return typeof offsetSpacing === "object" ? offsetSpacing.cross : 0;
}

export const AnchoredSheetHost: AnchoredSheetDefinition["Host"] = ({
  children,
  open,
  onClose,
  anchor,
  placement = "bottom-start",
  offsetSpacing = "S8",
  followRefWidth,
  bubbleOnClose,
  withoutBackdrop,
  lockPlacement,
  preventOverflow,
  noPointerEvents,
}) => {
  const classes = useStyles();
  const nodeId = useFloatingNodeId();

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (newOpen === false) {
        onClose();
      }
    },
    [onClose],
  );

  const mainAxis = getMainAxisSpacingFromOffset(offsetSpacing);
  const crossAxis = getCrossAxisSpacingFromOffset(offsetSpacing);
  const mainAxisoffsetSpacingNumber = getSpacingFromSizeValue(mainAxis);
  const crossAxisoffsetSpacingNumber = getSpacingFromSizeValue(crossAxis);

  const { refs, floatingStyles, context } = useFloating({
    open,
    elements: {
      reference:
        anchor?.current && anchor.current instanceof Element
          ? anchor?.current
          : undefined,
    },
    onOpenChange: handleOpenChange,
    placement,
    middleware: [
      offset({
        mainAxis: mainAxisoffsetSpacingNumber,
        crossAxis: crossAxisoffsetSpacingNumber,
      }),
      !lockPlacement ? flip({ fallbackAxisSideDirection: "end" }) : undefined,
      size({
        apply({ availableHeight, elements, rects, placement: _placement }) {
          const offsetSpacingCompensation = isPlacementHorizontal(_placement)
            ? 0
            : mainAxisoffsetSpacingNumber;

          if (
            preventOverflow &&
            availableHeight < elements.floating.scrollHeight
          ) {
            elements.floating.style.maxHeight = `${
              availableHeight - Spacing.S8 - offsetSpacingCompensation
            }px`;
          }

          if (followRefWidth) {
            elements.floating.style.width = `${rects.reference.width}px`;
          }
        },
      }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
    nodeId,
  });

  // https://github.com/floating-ui/floating-ui/issues/2458#issuecomment-1646510707
  useLayoutEffect(() => {
    if (!anchor?.current) {
      return;
    }
    if (anchor.current instanceof Element) {
      return;
    }
    if (anchor.current instanceof Component) {
      return;
    }
    if (!open) {
      return;
    }

    refs.setPositionReference(anchor?.current);
  }, [refs, anchor, open]);

  const dismiss = useDismiss(context, {
    ancestorScroll: withoutBackdrop,
    bubbles: bubbleOnClose ?? false, // allows the sheet to be dismissed without dismissing the parent. Ex: database filter menu
  });

  const { getFloatingProps } = useInteractions([dismiss]);

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: ANCHORED_SHEET_TRANSITION_DURATION,
    initial: {
      transform: getScale(0.75),
      opacity: 0,
    },
    common: ({ placement: _placement }) => {
      const reference = context.refs.reference.current?.getBoundingClientRect();
      const floating = context.refs.floating.current?.getBoundingClientRect();

      const { x, y } =
        reference && floating
          ? calculateRelativePosition(reference, floating)
          : { x: 0, y: 0 };

      return {
        transformOrigin: `${x * 100}% ${y * 100}%`,
        transform: getScale(1),
        opacity: 1,
      };
    },
  });

  const stopPropagation = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.stopPropagation();
    },
    [],
  );

  return (
    <FloatingNode id={nodeId}>
      {isMounted ? (
        <FloatingPortal>
          <FloatingOverlay
            className={classes.overlay}
            onClick={stopPropagation}
            style={{ pointerEvents: withoutBackdrop ? "none" : undefined }}
          >
            <FloatingFocusManager
              context={context}
              modal={false}
              visuallyHiddenDismiss
              closeOnFocusOut={false}
            >
              <div
                className={clsx(
                  "active-popover",
                  classes.content,
                  !open && classes.noPointerEvents,
                )}
                ref={refs.setFloating}
                style={floatingStyles}
                {...getFloatingProps()}
              >
                <div
                  style={styles}
                  className={clsx(
                    classes.sheetContent,
                    noPointerEvents
                      ? classes.noPointerEvents
                      : classes.pointerEvents,
                  )}
                >
                  {children}
                </div>
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      ) : null}
    </FloatingNode>
  );
};

const useStyles = makeStyles({
  overlay: {
    zIndex: 1550, // above all (including popup sheets and legacy dialogs)
    display: "flex",
  },
  content: {
    display: "flex",
  },
  pointerEvents: {
    pointerEvents: "all",
  },
  noPointerEvents: {
    "& *": {
      pointerEvents: "none",
    },
  },
  sheetContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
    width: "100%",
  },
});
