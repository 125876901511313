import { Pressable, StyleSheet, View } from "react-native";

import { ColorStyle, Spacing } from "../../../constants";
import { Icon } from "../../../display/icon";
import { Text } from "../../../display/text";
import type { PopupSheetDefinition } from "./popupSheet.definition/popupSheet.definition.types";

export const PopupSheetHeader: PopupSheetDefinition["Header"] = ({
  children,
  onClose,
}) => {
  return (
    <View style={styles.container}>
      <Text
        weight="medium"
        size="TITLE"
        color="FONT_HIGH_EMPHASIS"
        numberOfLines={2}
      >
        {children}
      </Text>
      <Pressable onPress={onClose} accessibilityLabel="Close dialog">
        <Icon name="x-close" color={ColorStyle.FONT_HIGH_EMPHASIS} />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: Spacing.S16,
    gap: Spacing.S16,
  },

  separator: {
    height: StyleSheet.hairlineWidth,
    flexGrow: 1,
    backgroundColor: ColorStyle.SEPARATOR,
  },
});
