import { useCallback } from "react";
import {
  Pressable,
  type PressableStateCallbackType,
  StyleSheet,
} from "react-native";

import { Icon } from "../../../display/icon";

interface AnchoredSheetHeaderBackButtonProps {
  onPress?: () => void;
}

export const AnchoredSheetHeaderBackButton = ({
  onPress,
}: AnchoredSheetHeaderBackButtonProps) => {
  const getPressableStyle = useCallback(
    (state: PressableStateCallbackType) => [
      state.pressed && styles.containerPressed,
    ],
    [],
  );

  return (
    <Pressable
      style={getPressableStyle}
      onPress={onPress}
      accessibilityLabel="close"
    >
      <Icon name="chevron-left" color="BLUE_KRAAFT" size="MEDIUM" />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  containerPressed: {
    opacity: 0.75,
  },
});
