import type React from "react";
import { SyntheticEvent } from "react";

export function isDOMElement(element: React.ReactElement): boolean {
  return typeof element.type === "string";
}

export function isSyntheticEvent(event: any): event is SyntheticEvent {
  return event?.nativeEvent !== undefined;
}
