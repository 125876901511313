import { ReactNode } from "react";
import { View } from "react-native";

import { Sheet } from "../sheet";

interface TooltipSheetProps {
  content: ReactNode;
}

export const TooltipSheet = Sheet({
  web: "anchored",
})
  .create<TooltipSheetProps>(({ Paper }) => ({ content }) => {
    return (
      <Paper noPadding>
        <View>{content}</View>
      </Paper>
    );
  })
  .withDefaults({
    withoutBackdrop: true,
    noPointerEvents: true,
    placement: "bottom",
  });
