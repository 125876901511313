import { StyleSheet, View } from "react-native";

import { Spacing } from "../../../constants/spacing";
import type { AnchoredSheetDefinition } from "./anchoredSheet.definition/anchoredSheet.definition.types";

export const AnchoredSheetFooter: AnchoredSheetDefinition["Footer"] = ({
  children,
}) => {
  return <View style={styles.container}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: Spacing.S16,
    gap: Spacing.S16,
  },
});
