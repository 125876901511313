import type { XYCoord } from "react-dnd";
import { assert } from "ts-essentials";

import type { ReorderPlacement } from "../orderableList.types";

export function getPlacementFromTargetAndDraggedSourcePosition(
  targetBoundingRect: DOMRect,
  draggedSourcePosition: XYCoord | null,
): ReorderPlacement {
  const targetMiddleY =
    (targetBoundingRect.bottom - targetBoundingRect.top) / 2;

  assert(draggedSourcePosition !== null, "clientOffset is null");

  return draggedSourcePosition.y - targetBoundingRect.top < targetMiddleY
    ? "before"
    : "after";
}
