import { type PropsWithChildren, useRef, useState } from "react";

import { uuid } from "@kraaft/helper-functions";
import { createMeshContext, useMeshContextSetup } from "@kraaft/helper-hooks";

import type {
  OrderableListHoveredItem,
  SourceType,
} from "./orderableList.types";

interface OrderableListContextValue {
  sourceType: SourceType;
  onDragStart: (() => void) | undefined;
  onDragEnd: (() => void) | undefined;
  identifier: string;
  currentlyHoveredRow: OrderableListHoveredItem | null;
  setCurrentlyHoveredRow: (
    newCurrentlyHoveredRow: OrderableListHoveredItem | null,
  ) => void;
  disabled: boolean;
}

export const OrderableListContext =
  createMeshContext<OrderableListContextValue>();

interface OrderableListContextProviderProps {
  sourceType: SourceType;
  onDragStart: (() => void) | undefined;
  onDragEnd: (() => void) | undefined;
  disabled?: boolean;
}

export const OrderableListContextProvider = ({
  children,
  sourceType,
  onDragStart,
  onDragEnd,
  disabled = false,
}: PropsWithChildren<OrderableListContextProviderProps>) => {
  const identifier = useRef(uuid()).current;

  const [currentlyHoveredRow, setCurrentlyHoveredRow] =
    useState<OrderableListHoveredItem | null>(null);

  const contextValue = useMeshContextSetup<OrderableListContextValue>({
    sourceType,
    onDragStart,
    onDragEnd,
    identifier,
    currentlyHoveredRow,
    setCurrentlyHoveredRow,
    disabled,
  });

  return (
    <OrderableListContext.Provider value={contextValue}>
      {children}
    </OrderableListContext.Provider>
  );
};
