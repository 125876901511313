import { StyleSheet, View } from "react-native";

import { ColorStyle, Radius, Spacing } from "../../constants";
import { Preloader, Text } from "../../display";

interface FakeTextInputWithLabelProps {
  label: string;
  value?: string | null | undefined;
  loading?: boolean;
}

export const FakeTextInputWithLabel = ({
  label,
  value,
  loading,
}: FakeTextInputWithLabelProps) => {
  return (
    <View style={styles.container}>
      <Text color="FONT_LOW_EMPHASIS" size="SMALL">
        {label}
      </Text>
      {loading ? (
        <Preloader
          size="small"
          color={ColorStyle.FONT_LOW_EMPHASIS}
          style={styles.loader}
        />
      ) : (
        <Text color="FONT_LOW_EMPHASIS" size="BODY">
          {value ?? " "}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: Spacing.S8,
    paddingHorizontal: Spacing.S16,
    gap: Spacing.S4,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: Radius.SMALL,
  },
  loader: {
    alignSelf: "flex-start",
  },
});
