import type { FontWeight } from "./text.types";

export function getFontFamilyFromWeight(weight: FontWeight) {
  switch (weight) {
    case "bold":
      return "Apercu-Bold";
    case "medium":
      return "Apercu-Medium";
    case "regular":
      return "Apercu";
    case "light":
      return "Apercu-Light";
  }
}
