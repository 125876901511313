import moment from "moment";

import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";

import { availableLanguages } from "@kraaft/shared/core/services/i18next/i18nextResources";
import { findClosestLanguage } from "@kraaft/shared/core/services/i18next/i18nextUtils";

const DEFAULT_FALLBACK = "en-gb";

// see https://github.com/moment/moment/issues/3624 for a discussion
export function setMomentLocale(code: string) {
  const momentLocale = getMomentLocale(code);
  moment.locale(momentLocale);
}

function getMomentLocale(code: string): string {
  if (!code) {
    return DEFAULT_FALLBACK;
  }

  const result = findClosestLanguage(availableLanguages, code);

  switch (result) {
    case "fr-FR":
      return "fr";
    case "en-US":
      return "en";
    case "en-GB":
      return "en-gb";
    case "it-IT":
      return "it";
    case "de-DE":
      return "de";
    case "es-ES":
      return "es";
    case undefined:
      return DEFAULT_FALLBACK;
  }
}
