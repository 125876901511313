export const CarouselUtils = {
  shouldRender(index: number, focused: number) {
    return Math.abs(focused - index) <= 1;
  },
  getIndexesToRender(focused: number) {
    if (focused === 0) {
      return [focused, focused + 1];
    }
    return [focused - 1, focused, focused + 1];
  },
};
