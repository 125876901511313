import { ReactNode } from "react";

import { useBooleanState } from "@kraaft/helper-hooks";

import { ActionSheetItem } from "./actionSheetItem";

interface ActionSheetGroupProps {
  label: string;
  children: ReactNode;
}

export const ActionSheetGroup = ({
  label,
  children,
}: ActionSheetGroupProps) => {
  const [isOpen, open] = useBooleanState(false);

  return !isOpen ? (
    <ActionSheetItem
      icon="chevron-down-double"
      label={label}
      onClick={open}
      preventAutoClose
    />
  ) : (
    children
  );
};
