import moment from "moment";

import "intl-pluralrules";

import { initReactI18next } from "react-i18next";
import baseI18n from "i18next";

import { dateFormatter } from "@kraaft/shared/core/services/dateFormatter/dateFormatter.provider";
import { availableLanguages } from "@kraaft/shared/core/services/i18next/i18nextResources";
import {
  findClosestLanguage,
  getFallbackLanguages,
} from "@kraaft/shared/core/services/i18next/i18nextUtils";
import { LazyLoadingLocales } from "@kraaft/shared/core/services/i18next/lazyLoading";
import { I18nKeys } from "@kraaft/shared/core/services/i18next/locales/i18n.keys";
import { numberFormatter } from "@kraaft/shared/core/services/numberFormatter";

import { languageDetector } from "./languageDetector/languageDetectorService";

baseI18n
  .use(initReactI18next)
  .use(languageDetector)
  .use(LazyLoadingLocales)
  .init({
    debug: false,
    fallbackLng: getFallbackLanguages,
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, format) => {
        if (value instanceof Date) {
          return moment(value).format(format);
        }
        return value;
      },
    },
  })
  .then(() => {
    const closestAvailableLanguage =
      findClosestLanguage(availableLanguages, i18n.language) ?? "fr-FR";

    dateFormatter.setLocale(closestAvailableLanguage);
    numberFormatter.setLocale(closestAvailableLanguage);
  })
  .catch(console.error);

type SimpleJsonObject = {
  [s: string]:
    | string
    | number
    | boolean
    | Date
    | undefined
    | null
    | SimpleJsonObject;
};
export type TFunction = (key: I18nKeys, params?: SimpleJsonObject) => string;
export type ImprovedI18n = Omit<typeof baseI18n, "t"> & {
  t: TFunction;
};
export type ResourceKey = I18nKeys;
export const i18n = baseI18n as ImprovedI18n;
