import { Pressable, StyleProp, type ViewStyle } from "react-native";

import { useVoidOnPress } from "@kraaft/helper-hooks";
import { Icon, type IconName, PixelSize } from "@kraaft/ui";

interface CarouselIconButtonProps {
  icon: IconName;
  onPress(): void;
  accessibilityLabel: string;
  style?: StyleProp<ViewStyle>;
}

export const CarouselIconButton = ({
  icon,
  onPress,
  accessibilityLabel,
  style,
}: CarouselIconButtonProps) => {
  const handlePress = useVoidOnPress(onPress);

  return (
    <Pressable
      hitSlop={PixelSize.S32}
      style={style}
      onPress={handlePress}
      accessibilityLabel={accessibilityLabel}
    >
      <Icon name={icon} color="WHITE" />
    </Pressable>
  );
};
