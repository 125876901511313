import type { ReactNode } from "react";
import type { StyleProp, ViewStyle } from "react-native";
import Animated, {
  useAnimatedStyle,
  withTiming,
} from "react-native-reanimated";

import { CAROUSEL_UI_SHOW_HIDE_MS } from "./carousel.constants";
import { useCarousel } from "./carouselContext";

interface CarouselUIProps {
  index: number;
  children: ReactNode;
  inverted?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const CarouselUI = ({
  index,
  children,
  style,
  inverted,
}: CarouselUIProps) => {
  const { displayUi } = useCarousel();

  const shouldDisplay = (displayUi && !inverted) || (!displayUi && inverted);

  const opacity = useAnimatedStyle(() => ({
    opacity: withTiming(shouldDisplay ? 1 : 0, {
      duration: CAROUSEL_UI_SHOW_HIDE_MS,
    }),
  }));

  return (
    <Animated.View
      style={[
        opacity,
        style,
        { pointerEvents: shouldDisplay ? undefined : "none" },
      ]}
      pointerEvents={shouldDisplay ? "auto" : "none"}
    >
      {children}
    </Animated.View>
  );
};
