import { PopupSheetContent } from "../popupSheet.content";
import { PopupSheetFooter } from "../popupSheet.footer";
import { PopupSheetGrowingContent } from "../popupSheet.growingContent";
import { PopupSheetHeader } from "../popupSheet.header";
import { PopupSheetHost } from "../popupSheet.host";
import { PopupSheetPaper } from "../popupSheet.paper";
import type { PopupSheetDefinition } from "./popupSheet.definition.types";

export const popupSheetDefinition: PopupSheetDefinition = {
  Host: PopupSheetHost,
  Paper: PopupSheetPaper,
  Header: PopupSheetHeader,
  Content: PopupSheetContent,
  GrowingContent: PopupSheetGrowingContent,
  Footer: PopupSheetFooter,
};
