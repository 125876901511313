import { useCallback } from "react";

import { compact } from "@kraaft/helper-functions";

export function useCombinedRefs<T>(
  ...refs: (React.Ref<T> | undefined)[]
): React.RefCallback<T> {
  return useCallback((element: T) => {
    for (const ref of compact(refs)) {
      if (typeof ref === "function") {
        ref(element);
      } else if (ref && typeof ref === "object") {
        (ref as React.MutableRefObject<T>).current = element;
      }
    }
    // we want to explicitly listen for ref change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
}
