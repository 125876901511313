import {
  Context,
  createElement,
  type PropsWithChildren,
  useContext,
  useEffect,
  useRef,
} from "react";

import { type MeshContext, useMeshContext } from "@kraaft/helper-hooks";

import { DEFAULT_PORTAL_HOSTNAME } from "../portalConstants";
import { PortalManagementContext } from "../portalContext";

export interface PortalProps {
  hostname?: string;
  forwardContext?: Array<Context<any>>;
  forwardMeshContext?: Array<MeshContext<any>>;
}

export const Portal = ({
  children,
  hostname = DEFAULT_PORTAL_HOSTNAME,
  forwardContext,
  forwardMeshContext,
}: PropsWithChildren<PortalProps>) => {
  const { addElement, removeElement } = useContext(PortalManagementContext);
  const key = useRef(Date.now() + Math.random().toString(36));

  const contextWrapped =
    forwardContext?.reduce((acc, Forwarded) => {
      return createElement(Forwarded.Provider, {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        value: useContext(Forwarded),
        // biome-ignore lint/correctness/noChildrenProp: <explanation>
        children: acc,
      });
    }, children) ?? children;

  const meshContextWrapped =
    forwardMeshContext?.reduce((acc, Forwarded) => {
      return createElement(Forwarded.Provider, {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        value: useMeshContext(Forwarded),
        // biome-ignore lint/correctness/noChildrenProp: <explanation>
        children: acc,
      });
    }, contextWrapped) ?? contextWrapped;

  useEffect(() => {
    addElement(meshContextWrapped, key.current, hostname);
  }, [addElement, hostname, meshContextWrapped]);

  useEffect(
    () => () => removeElement(key.current, hostname),
    [removeElement, hostname],
  );

  return null;
};
