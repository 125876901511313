import { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { Color } from "../../constants/color";
import { Radius } from "../../constants/radius";
import { Text } from "../../display/text";

export interface DropIndicatorProps {
  isActive: boolean;
  text?: string;
}

export const DropIndicator = (props: DropIndicatorProps) => {
  const { isActive, text } = props;

  const containerStyle = useMemo(
    () =>
      StyleSheet.flatten([
        styles.container,
        Boolean(text) && styles.containerWithText,
        isActive && styles.activeBorder,
      ]),
    [isActive, text],
  );

  return (
    <View style={containerStyle} pointerEvents="none">
      <Text size="BODY" color="FONT_HIGH_EMPHASIS">
        {text}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    borderStyle: "solid",
    borderRadius: Radius.SMALL,
    alignItems: "center",
    justifyContent: "center",

    borderWidth: 1,
    borderColor: Color.BLUE_KRAAFT,
  },
  containerWithText: {
    backgroundColor: Color.BLUE_COOL,
  },
  activeBorder: {
    /** for some reason; 2px appears to be doing the same as 1px */
    borderWidth: 3,
  },
});
