import type { Size } from "@kraaft/shared/core/types";

export interface OrderableListRow {
  index: number;
}
export type OrderableListRows<T extends OrderableListRow> = Record<string, T>;

export interface OrderedListRow<T = OrderableListRow> {
  key: string;
  computedIndex: number;
  data: T;
}
export type OrderedListRows<T = OrderableListRow> = OrderedListRow<T>[];

export type ReorderPlacement = "before" | "after";
export type TransitionDirection = "up" | "down";
export type Identifier = string | symbol;
export type SourceType = Identifier;
export type TargetType = Identifier | Identifier[];

export type ReorderFunction = (
  sourceKey: string,
  placement: ReorderPlacement,
  targetKey: string,
) => void;

export const ORDERABLE_LIST_DRAG_ITEM_TYPE = "OrderableListDragItem";
export interface OrderableListDragItem<T> {
  type: typeof ORDERABLE_LIST_DRAG_ITEM_TYPE;
  sourceKey: string;
  sourceIndex: number;
  listIdentifier: string;
  placeholderSize: Size;
  data: T;
}

export interface OrderableListDraggedItem {
  key: string;
  index: number;
}

export interface OrderableListHoveredItem {
  key: string;
  index: number;
  placement: ReorderPlacement;
}

export interface RowDropInfo {
  key: string;
  placement: ReorderPlacement;
}

export type ReorderedIds = Record<string, number>;
