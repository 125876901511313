import { isNative } from "@kraaft/helper-functions";
import { GeoCoordinates } from "@kraaft/shared/core/types";

export const constants = {
  CONFIRMATION_TIME: 100, // Time before going back on single-select press for UX purposes
  POOL_SELECTION_TIME: 25,
  FILE_SIZE_LIMIT: 1000000000, // 1000MB
  FREEMIUM_CONTENT_LIMIT_DAYS: 15,
  PRESS_TO_RECORD_DELAY: 700,
  CAMERA_PILL_ZOOM_TIME: 200,
  CAMERA_FORMAT_THROTTLE: 750,
};

export const FRANCE_COORDINATES: GeoCoordinates = {
  latitude: 46.2276,
  longitude: 2.2137,
};

export const DATADOG_CONFIG = isNative()
  ? {
      applicationId: "f158c852-464b-40b3-8295-5d230d7258a5",
      clientToken: "pub7f819d24b7ea29f83255accdcdb2d673",
      region: "EU1",
    }
  : {
      applicationId: "e64de206-9a24-401a-8c0a-ad671098ce70",
      clientToken: "pub780ca4216a6a64548c8987fa3466d7c0",
      region: "datadoghq.eu",
    };

export const MODULAR_LABEL_LIMIT = {
  SECTION_LABEL: 150,
  COLUMN_LABEL: 250,
};
