import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";
import { intlPolyfill } from "@kraaft/shared/core/services/dateFormatter/intlPolyfill";

type RelativeTimeUnit =
  | "seconds"
  | "minutes"
  | "hours"
  | "days"
  | "weeks"
  | "months"
  | "years";

const MILLISECONDS_TO_UNIT = {
  years: 24 * 60 * 60 * 1000 * 365,
  months: 24 * 60 * 60 * 1000 * 30,
  weeks: 24 * 60 * 60 * 1000 * 7,
  days: 24 * 60 * 60 * 1000,
  hours: 60 * 60 * 1000,
  minutes: 60 * 1000,
  seconds: 1000,
} satisfies Record<RelativeTimeUnit, number>;

export class DateFormatter {
  constructor(private locale: PoolLanguage) {}
  setLocale(locale: PoolLanguage) {
    this.locale = locale;
    intlPolyfill(locale).catch(console.error);
  }

  format(date: Date) {
    return new Intl.DateTimeFormat(this.locale, {
      year: "numeric",
      weekday: "long",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(date);
  }

  formatDateShort(date: Date) {
    return new Intl.DateTimeFormat(this.locale, {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    }).format(date);
  }

  formatTimeShort(date: Date) {
    return new Intl.DateTimeFormat(this.locale, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(date);
  }

  getLabelForDeltaInUnit(lhs: Date, rhs: Date, unit: RelativeTimeUnit) {
    const relativeTimeFormatter = new Intl.RelativeTimeFormat(this.locale, {
      localeMatcher: "lookup",
      numeric: "auto",
    });

    const delta = (lhs.getTime() - rhs.getTime()) / MILLISECONDS_TO_UNIT[unit];

    return relativeTimeFormatter.format(Math.ceil(delta), unit);
  }

  /**
   * < 1 minute : "44 seconds ago"
   * < 1 hour : "54 minutes ago"
   * < 1 day : "2 hours ago"
   * < 1 week : "4 days ago"
   * < 1 month : "3 weeks ago"
   * < 1 year : "10 months ago"
   * >= 1 year : "3 years ago"
   */
  getRelativeLabel(lhs: Date, rhs: Date) {
    const delta = Math.abs(lhs.getTime() - rhs.getTime());

    if (delta < MILLISECONDS_TO_UNIT.minutes) {
      return this.getLabelForDeltaInUnit(lhs, rhs, "seconds");
    }
    if (delta < MILLISECONDS_TO_UNIT.hours) {
      return this.getLabelForDeltaInUnit(lhs, rhs, "minutes");
    }
    if (delta < MILLISECONDS_TO_UNIT.days) {
      return this.getLabelForDeltaInUnit(lhs, rhs, "hours");
    }
    if (delta < MILLISECONDS_TO_UNIT.weeks) {
      return this.getLabelForDeltaInUnit(lhs, rhs, "days");
    }
    if (delta < MILLISECONDS_TO_UNIT.months) {
      return this.getLabelForDeltaInUnit(lhs, rhs, "weeks");
    }
    if (delta < MILLISECONDS_TO_UNIT.years) {
      return this.getLabelForDeltaInUnit(lhs, rhs, "months");
    }

    return this.getLabelForDeltaInUnit(lhs, rhs, "years");
  }
}
