import { TextInput, type TextInputProps } from "react-native";
import Animated, {
  type SharedValue,
  useAnimatedProps,
} from "react-native-reanimated";

const MyAnimatedText = Animated.createAnimatedComponent(TextInput);
Animated.addWhitelistedNativeProps({ text: true });

export interface AnimatedTextProps {
  text: SharedValue<string>;
  style?: TextInputProps["style"];
}

const AnimatedText = ({ text, style }: AnimatedTextProps) => {
  const animatedProps = useAnimatedProps(() => ({ text: text.value }) as any);

  return (
    <MyAnimatedText
      animatedProps={animatedProps}
      value={text.value}
      style={style}
      readOnly
    />
  );
};

export { AnimatedText };
