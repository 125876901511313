import deburr from "lodash/deburr";
import maxBy from "lodash/maxBy";

export function normalizeTextForSearch(text: string | undefined) {
  return deburr(text?.toLowerCase());
}

export function longuestNonTemplatedText(text: string) {
  const slices = text.split(/<.*?>|{{.*?}}/);
  return maxBy(slices, (slice) => slice.length) ?? "";
}
