import { XYCoord } from "react-dnd";

export const getTranslationStyle = (currentOffset: XYCoord | null) => {
  if (!currentOffset) {
    return { display: "none" };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
};
