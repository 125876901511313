import { useRef } from "react";
import { type SharedValue, useSharedValue } from "react-native-reanimated";

import { createMeshContext, useMeshContextSetup } from "@kraaft/helper-hooks";

import type { VideoPlayerHandle } from "./videoPlayer.types";

export interface VideoPlayerContext extends VideoPlayerHandle {
  playing: SharedValue<boolean>;
  progressMs: SharedValue<number>;
  durationMs: SharedValue<number>;
}

export const VideoPlayerContext = createMeshContext<VideoPlayerContext>();

export function useVideoPlayerContext() {
  const progressMs = useSharedValue(0);
  const durationMs = useSharedValue(0);
  const playing = useSharedValue(false);

  const videoHandle = useRef<VideoPlayerHandle>(null);

  const context = useMeshContextSetup<VideoPlayerContext>({
    progressMs,
    durationMs,
    playing,
    pause() {
      videoHandle.current?.pause();
    },
    play(positionMs) {
      videoHandle.current?.play(positionMs);
    },
    setPosition(positionMs) {
      videoHandle.current?.setPosition(positionMs);
    },
  });

  return { ref: videoHandle, context };
}
