import { useMemo } from "react";
import { Image, ImageStyle } from "react-native";

import KraaftLogoWithText from "./logo_kraaft_144.png";

interface Props {
  height?: number;
  style?: ImageStyle;
}

const KraaftLogo = (props: Props) => {
  const { height = 48, style } = props;
  const width = 467 * (height / 144);
  const finalStyle = useMemo(
    () => [style, { height, width }],
    [height, style, width],
  );

  return <Image source={KraaftLogoWithText} style={finalStyle} />;
};

export { KraaftLogo };
