import { useCallback, useMemo, useRef } from "react";
import {
  type LayoutChangeEvent,
  type StyleProp,
  StyleSheet,
  View,
  type ViewStyle,
} from "react-native";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import Animated, {
  runOnJS,
  type SharedValue,
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";

import { clamp } from "@kraaft/helper-functions";

import { Color } from "../../constants";

interface SliderProps {
  min: number;
  max: number;
  value: number | SharedValue<number>;
  onSeek(value: number): void;
  onSlide?(value: number): void;
  onBeginSlide?(): void;
  style?: StyleProp<ViewStyle>;
}

function usePossiblySharedValue(value: number | SharedValue<number>) {
  const wasShared = useRef<boolean | undefined>(undefined);

  if (typeof value === "number") {
    if (wasShared.current === true) {
      throw new Error("Cannot change from primitive value to shared value");
    }
    wasShared.current = false;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSharedValue(value);
  }
  if (wasShared.current === false) {
    throw new Error("Cannot change from shared value to primitive value");
  }
  wasShared.current = true;
  return value;
}

const BAR_HEIGHT = 2;
const DOT_HITBOX_HEIGHT = 80;
const DOT_HEIGHT = 14;

export const Slider = ({
  value,
  min,
  max,
  onSeek,
  style,
  onBeginSlide,
  onSlide,
}: SliderProps) => {
  const isGesturing = useSharedValue(false);

  const x = useSharedValue(0);
  const startX = useSharedValue(0);
  const width = useSharedValue(0);

  const sharedValue = usePossiblySharedValue(value);

  useAnimatedReaction(
    () => sharedValue.value,
    (prepared, previous) => {
      if (prepared !== previous && !isGesturing.value) {
        x.value = prepared * width.value;
      }
    },
  );

  const gesture = useMemo(
    () =>
      Gesture.Pan()
        .shouldCancelWhenOutside(false)
        .onBegin(() => {
          isGesturing.value = true;
          onBeginSlide ? runOnJS(onBeginSlide)() : undefined;
        })
        .onStart(() => {
          startX.value = x.value;
        })
        .onUpdate((event) => {
          const wantedValue = clamp(
            startX.value + event.translationX,
            0,
            width.value,
          );
          x.value = wantedValue;
          onSlide ? runOnJS(onSlide)(wantedValue / width.value) : undefined;
        })
        .onEnd(() => {
          runOnJS(onSeek)((x.value / width.value) * max - min);
          isGesturing.value = false;
        }),
    [
      isGesturing,
      max,
      min,
      onBeginSlide,
      onSeek,
      onSlide,
      startX,
      width.value,
      x,
    ],
  );

  const handleLayout = useCallback(
    (event: LayoutChangeEvent) => {
      width.value = event.nativeEvent.layout.width;
      x.value =
        (sharedValue.value / (max - min)) * event.nativeEvent.layout.width;
    },
    [max, min, sharedValue.value, width, x],
  );

  const dotStyle = useAnimatedStyle(() => ({
    transform: [
      { translateY: -(DOT_HITBOX_HEIGHT / 2) + BAR_HEIGHT / 2 },
      { translateX: x.value },
      { translateX: -(DOT_HITBOX_HEIGHT / 2) },
    ],
  }));

  const elapsedStyle = useAnimatedStyle(() => ({
    width: x.value,
  }));

  return (
    <View style={[styles.bar, style]} onLayout={handleLayout}>
      <Animated.View style={[styles.elapsed, elapsedStyle]} />
      <GestureDetector gesture={gesture}>
        <Animated.View style={[styles.dotHitbox, dotStyle]}>
          <View style={styles.dot} />
        </Animated.View>
      </GestureDetector>
    </View>
  );
};

const styles = StyleSheet.create({
  bar: {
    height: BAR_HEIGHT,
    backgroundColor: "grey",
    borderRadius: BAR_HEIGHT,
  },
  elapsed: {
    position: "absolute",
    left: 0,
    right: 0,
    backgroundColor: Color.WHITE,
    height: BAR_HEIGHT,
  },
  dotHitbox: {
    height: DOT_HITBOX_HEIGHT,
    width: DOT_HITBOX_HEIGHT,
    borderRadius: DOT_HITBOX_HEIGHT,
    alignItems: "center",
    justifyContent: "center",
  },
  dot: {
    height: DOT_HEIGHT,
    width: DOT_HEIGHT,
    backgroundColor: Color.WHITE,
    borderRadius: DOT_HEIGHT,
  },
});
