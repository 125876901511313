import {
  type ContentBlock,
  type ContentState,
  EditorState,
  Modifier,
  type RawDraftContentState,
  SelectionState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";

import { uuid } from "@kraaft/helper-functions";
import { compareStrings } from "@kraaft/shared/core/utils";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";

import type { Suggestion } from "./types/suggestions";

interface RichTextBlockEntityRange {
  offset: number;
  length: number;
  key: number;
}

interface RichTextBlock {
  text: string;
  entityRanges: RichTextBlockEntityRange[];
}

interface RichTextEntity {
  data: {
    value: string;
  };
}

interface RichTextContent {
  blocks: RichTextBlock[];
  entities: Record<string, RichTextEntity>;
}

export function addSuggestion(
  editorState: EditorState,
  suggestion: Suggestion,
  suggestionPrefix: string | undefined,
  entityMutability: "SEGMENTED" | "IMMUTABLE" | "MUTABLE",
): EditorState {
  const contentStateWithEntity = editorState
    .getCurrentContent()
    .createEntity("mention", entityMutability, {
      mention: suggestion,
    });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const currentSelectionState = editorState.getSelection();

  const mentionReplacedContent = Modifier.replaceText(
    editorState.getCurrentContent(),
    currentSelectionState,
    `${suggestionPrefix ?? ""}${suggestion.name}`,
    editorState.getCurrentInlineStyle(),
    entityKey,
  );

  const newEditorState = EditorState.push(
    editorState,
    mentionReplacedContent,
    "insert-fragment",
  );
  return EditorState.forceSelection(
    newEditorState,
    mentionReplacedContent.getSelectionAfter(),
  );
}

export const customSuggestionsFilter = (
  searchValue: string,
  suggestions: Suggestion[],
): Suggestion[] => {
  const value = normalizeTextForSearch(searchValue);
  const filteredSuggestions = suggestions.filter(
    (suggestion) =>
      !value ||
      normalizeTextForSearch(suggestion.name).indexOf(value) > -1 ||
      normalizeTextForSearch(suggestion.placeholder).indexOf(value) > -1,
  );
  return filteredSuggestions.sort((a, b) => compareStrings(a.label, b.label));
};

export const normalizeEditorContent = (
  editorState: EditorState,
): RichTextContent => {
  const editorContent = convertToRaw(editorState.getCurrentContent());

  const richTextContent: RichTextContent = {
    blocks: [],
    entities: {},
  };

  richTextContent.blocks = editorContent.blocks.map((block) => ({
    text: block.text,
    entityRanges: block.entityRanges,
  }));

  for (const [entityKey, entity] of Object.entries(editorContent.entityMap)) {
    const suggestionValue = entity.data[entity.type].value;
    if (suggestionValue) {
      richTextContent.entities[entityKey] = {
        data: {
          value: suggestionValue,
        },
      };
    }
  }

  return richTextContent;
};

const moveSelectionToEnd = (editorState: EditorState) => {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();

  const key = blockMap.last().getKey();
  const length = blockMap.last().getLength();

  const selection = new SelectionState({
    anchorKey: key,
    anchorOffset: length,
    focusKey: key,
    focusOffset: length,
  });
  return EditorState.forceSelection(editorState, selection);
};

export const createEditorStateFromRichTextContent = (
  richTextContent: RichTextContent,
  suggestions: Suggestion[],
): EditorState => {
  const rawEditorContent: RawDraftContentState = {
    blocks: [],
    entityMap: {},
  };

  rawEditorContent.blocks = richTextContent.blocks.map((block) => ({
    text: block.text,
    entityRanges: block.entityRanges,

    key: uuid(),
    type: "unstyled",
    depth: 0,
    inlineStyleRanges: [],
  }));

  for (const [entityKey, entity] of Object.entries(richTextContent.entities)) {
    const suggestionValue = entity.data.value;

    const suggestion = suggestions.find((s) => s.value === suggestionValue);

    if (suggestionValue) {
      rawEditorContent.entityMap[entityKey] = {
        type: "mention",
        mutability: "IMMUTABLE",
        data: {
          mention: suggestion,
        },
      };
    }
  }

  const editorState = EditorState.createWithContent(
    convertFromRaw(rawEditorContent),
  );

  return moveSelectionToEnd(editorState);
};

export const getFormattedTextWithSuggestions = (
  editorState: EditorState,
): string => {
  let editorContent = editorState.getCurrentContent();

  const entityDataMap: Record<string, Suggestion> = {};
  editorContent.getAllEntities().forEach((entity, key) => {
    if (key && entity) {
      const entityType = entity.getType();
      entityDataMap[key] = entity.getData()[entityType];
    }
  });

  for (const block of editorContent.getBlocksAsArray()) {
    let blockOffset = 0;
    block.findEntityRanges(
      (character) => character.getEntity() !== null,
      (_start, _end) => {
        const start = _start - blockOffset;
        const end = _end - blockOffset;
        const entityKey = block.getEntityAt(_start);
        const suggestion = getSuggestionFromBlock(
          editorContent,
          block,
          entityKey,
        );

        if (suggestion) {
          const selection = getSelectionForBlock(block, start, end);

          const newText = `{{${suggestion.value}}}`;

          editorContent = Modifier.replaceText(
            editorContent,
            selection,
            newText,
          );

          blockOffset += end - start - newText.length;
        }
      },
    );
  }

  return editorContent.getPlainText();
};

const getSuggestionFromBlock = (
  editorContent: ContentState,
  block: ContentBlock,
  entityKey: string,
): Suggestion | undefined => {
  const entity = editorContent.getEntity(entityKey);

  return entity.getData()[entity.getType()];
};

const getSelectionForBlock = (
  block: ContentBlock,
  start: number,
  end: number,
): SelectionState => {
  const blockKey = block.getKey();

  return new SelectionState({
    anchorKey: blockKey,
    anchorOffset: start,
    focusKey: blockKey,
    focusOffset: end,
  });
};

export const updateSuggestionsEntities = (
  editorState: EditorState,
  suggestions: Suggestion[],
  suggestionPrefix: string | undefined,
): EditorState => {
  let editorContent = editorState.getCurrentContent();

  for (const block of editorContent.getBlocksAsArray()) {
    block.findEntityRanges(
      (character) => character.getEntity() !== null,
      (start, end) => {
        const entityKey = block.getEntityAt(start);

        const suggestion = getSuggestionFromBlock(
          editorContent,
          block,
          entityKey,
        );

        if (suggestion) {
          const selection = getSelectionForBlock(block, start, end);

          const newSuggestion = suggestions.find(
            (s) => s.value === suggestion.value,
          );
          if (newSuggestion) {
            editorContent = editorContent.replaceEntityData(entityKey, {
              mention: newSuggestion,
            });

            editorContent = Modifier.replaceText(
              editorContent,
              selection,
              `${suggestionPrefix ?? ""}${newSuggestion.name}`,
              editorState.getCurrentInlineStyle(),
              entityKey,
            );
          }
        }
      },
    );
  }

  return EditorState.push(
    editorState,
    editorContent,
    editorState.getLastChangeType(),
  );
};

export const hasUnknownSuggestions = (
  editorState: EditorState,
  suggestions: Suggestion[],
): boolean => {
  const editorContent = editorState.getCurrentContent();
  let hasError = false;

  for (const block of editorContent.getBlocksAsArray()) {
    block.findEntityRanges(
      (character) => character.getEntity() !== null,
      (start) => {
        if (hasError) {
          return;
        }
        const suggestion = getSuggestionFromBlock(
          editorContent,
          block,
          block.getEntityAt(start),
        );

        if (suggestion) {
          const foundSuggestion = suggestions.find(
            (s) => s.value === suggestion.value,
          );
          if (!foundSuggestion) {
            hasError = true;
          }
        }
      },
    );
  }

  return hasError;
};
