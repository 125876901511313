import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { alphaHex } from "@kraaft/helper-functions";

import { Color, ColorStyle } from "../../../constants/color";
import { Radius } from "../../../constants/radius";
import { Spacing } from "../../../constants/spacing";
import type { AnchoredSheetDefinition } from "./anchoredSheet.definition/anchoredSheet.definition.types";

export const AnchoredSheetPaper: AnchoredSheetDefinition["Paper"] = ({
  children,
  maxHeight,
  width,
  noPadding,
  noGap,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.container,
        noPadding && classes.noPadding,
        noGap && classes.noGap,
      )}
      style={{ maxHeight, width }}
    >
      {children}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
    padding: `${Spacing.S16}px 0`,
    gap: Spacing.S16,
    borderRadius: Radius.SMALL,
    boxShadow: `0px 0px 5px ${alphaHex(Color.BLACK, 0.25)}`,
    overflow: "auto",
    flexShrink: 1,
  },
  noPadding: {
    padding: Spacing.NONE,
  },
  noGap: {
    gap: Spacing.NONE,
  },
});
