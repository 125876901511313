import { shouldPolyfill as shouldPolyfillCanonicalLocales } from "@formatjs/intl-getcanonicallocales/should-polyfill";
import { shouldPolyfill as shouldPolyfillLocale } from "@formatjs/intl-locale/should-polyfill";
import { shouldPolyfill as shouldPolyfillPluralRules } from "@formatjs/intl-pluralrules/should-polyfill";
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from "@formatjs/intl-relativetimeformat/should-polyfill";

import { assertNever } from "@kraaft/helper-functions";
import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";

async function intlGetCanonicalLocalesPolyfill() {
  if (shouldPolyfillCanonicalLocales()) {
    await import("@formatjs/intl-getcanonicallocales/polyfill");
  }
}

async function intlLocalePolyfill() {
  if (shouldPolyfillLocale()) {
    await import("@formatjs/intl-locale/polyfill");
  }
}

async function intlPluralRulesPolyfill(poolLanguage: PoolLanguage) {
  const [locale] = poolLanguage.split("-");
  if (locale === undefined) {
    return;
  }

  const unsupportedLocale = shouldPolyfillPluralRules(locale);
  if (!unsupportedLocale) {
    return;
  }

  await import("@formatjs/intl-pluralrules/polyfill-force");
  switch (poolLanguage) {
    case "fr-FR": {
      await import("@formatjs/intl-pluralrules/locale-data/fr");
      return;
    }
    case "en-US":
    case "en-GB": {
      await import("@formatjs/intl-pluralrules/locale-data/en");
      return;
    }
    case "it-IT": {
      await import("@formatjs/intl-pluralrules/locale-data/it");
      return;
    }
    case "de-DE": {
      await import("@formatjs/intl-pluralrules/locale-data/de");
      return;
    }
    case "es-ES": {
      await import("@formatjs/intl-pluralrules/locale-data/es");
      return;
    }
    default:
      assertNever(poolLanguage);
  }
}

async function intlRelativeTimeFormatPolyfill(poolLanguage: PoolLanguage) {
  const [locale] = poolLanguage.split("-");
  if (locale === undefined) {
    return;
  }

  const unsupportedLocale = shouldPolyfillRelativeTimeFormat(locale);
  if (!unsupportedLocale) {
    return;
  }

  await import("@formatjs/intl-relativetimeformat/polyfill-force");
  switch (poolLanguage) {
    case "fr-FR": {
      await import("@formatjs/intl-relativetimeformat/locale-data/fr");
      return;
    }
    case "en-US":
    case "en-GB": {
      await import("@formatjs/intl-relativetimeformat/locale-data/en");
      return;
    }
    case "it-IT": {
      await import("@formatjs/intl-relativetimeformat/locale-data/it");
      return;
    }
    case "de-DE": {
      await import("@formatjs/intl-relativetimeformat/locale-data/de");
      return;
    }
    case "es-ES": {
      await import("@formatjs/intl-relativetimeformat/locale-data/es");
      return;
    }
    default:
      assertNever(poolLanguage);
  }
}

export async function intlPolyfill(poolLanguage: PoolLanguage) {
  await intlGetCanonicalLocalesPolyfill();
  await intlLocalePolyfill();
  await intlPluralRulesPolyfill(poolLanguage);
  await intlRelativeTimeFormatPolyfill(poolLanguage);
}
