import { useMemo, useState } from "react";

export function useBooleanState(defaultValue = false) {
  const [open, setOpen] = useState(defaultValue);

  const [handleOpen, handleClose] = useMemo(
    () => [() => setOpen(true), () => setOpen(false)],
    [],
  );

  return [open, handleOpen, handleClose] as const;
}
