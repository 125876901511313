import { makeStyles } from "@mui/styles";
import type { PropsWithChildren } from "react";

import { Color } from "../../../constants/color";
import { Radius } from "../../../constants/radius";
import { Spacing } from "../../../constants/spacing";

export const BaseActionCardHoverWrapper = (props: PropsWithChildren) => {
  const classes = useStyles();

  return <div className={classes.hoveredContainer}>{props.children}</div>;
};

const useStyles = makeStyles({
  hoveredContainer: {
    flexGrow: 1,
    flexShrink: 1,
    "& > *": {
      "&:hover": {
        "&:before": {
          content: '""',
          position: "absolute",
          height: "100%",
          borderRadius: Radius.SMALL,
          width: `calc(100% + ${Spacing.S16}px)`,
          transform: `translateX(-${Spacing.S8}px)`,
          backgroundColor: Color.BLUE_LAVENDER,
        },
      },
    },
  },
});
