import { makeStyles } from "@mui/styles";
import type { PropsWithChildren } from "react";

import { DropIndicator } from "./dropIndicator";
import type { DroppableProps } from "./droppable.types";
import { useDroppable } from "./useDroppable";

export const Droppable = ({
  children,
  dropAcceptType,
  onDrop,
  onDropFiles,
  dropAcceptSystemFile,
  dropIndicatorText,
}: PropsWithChildren<DroppableProps>) => {
  const classes = useStyles();

  const [droppableContainerRef, { canDrop, isOver }] = useDroppable({
    acceptType: dropAcceptType,
    acceptSystemFile: dropAcceptSystemFile ?? true,
    onDrop,
    onDropFiles,
  });

  return (
    <div ref={droppableContainerRef} className={classes.container}>
      {children}
      {canDrop && <DropIndicator isActive={isOver} text={dropIndicatorText} />}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    width: "100%",
    position: "relative",
  },
});
