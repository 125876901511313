import { useCallback, useRef } from "react";
import { StyleSheet, View } from "react-native";
import {
  useAnimatedReaction,
  useDerivedValue,
  useSharedValue,
} from "react-native-reanimated";

import { useMeshContext } from "@kraaft/helper-hooks";

import { Color, Spacing } from "../../constants";
import { Slider } from "../../inputs/slider";
import { millisecondsToMinutesAndSeconds } from "../../utils";
import { AnimatedText } from "../animatedText";
import { VideoPlayerContext } from "./videoPlayerContext";

const REFRESH_VIDEO_PREVIEW_ON_SLIDE = 500;
const MAX_TIME_TEXT_WIDTH = 55;

export const VideoPlayerSlider = () => {
  const wasPlayingBeforeSliding = useRef(false);

  const progressTextLeft = useSharedValue("");
  const progressTextRight = useSharedValue("");

  const { progressMs, durationMs, pause, play, setPosition, playing } =
    useMeshContext(VideoPlayerContext);

  useAnimatedReaction(
    () => [progressMs.value, durationMs.value] as const,
    ([progressMsValue, durationMsValue]) => {
      progressTextLeft.value = millisecondsToMinutesAndSeconds(progressMsValue);
      progressTextRight.value = `-${millisecondsToMinutesAndSeconds(
        durationMsValue - progressMsValue,
      )}`;
    },
  );

  const handleSeek = useCallback(
    async (value: number) => {
      if (wasPlayingBeforeSliding.current) {
        play(value * durationMs.value);
      } else {
        setPosition(value * durationMs.value);
      }
    },
    [durationMs.value, play, setPosition],
  );

  const lastRequest = useRef(Date.now());
  const handleSlide = useCallback(
    async (value: number) => {
      const wantedProgress = value * durationMs.value;
      progressTextLeft.value = millisecondsToMinutesAndSeconds(wantedProgress);
      progressTextRight.value = `-${millisecondsToMinutesAndSeconds(
        durationMs.value - wantedProgress,
      )}`;

      const now = Date.now();
      if (now - lastRequest.current > REFRESH_VIDEO_PREVIEW_ON_SLIDE) {
        setPosition(wantedProgress);
        lastRequest.current = now;
      }
    },
    [durationMs, progressTextLeft, progressTextRight, setPosition],
  );

  const handleBeginSlide = useCallback(() => {
    wasPlayingBeforeSliding.current = playing.value;
    pause();
  }, [pause, playing.value]);

  const normalizedProgress = useDerivedValue(() => {
    return progressMs.value / durationMs.value;
  });

  return (
    <View style={styles.bar}>
      <AnimatedText
        style={[styles.progressText, styles.leftText]}
        text={progressTextLeft}
      />
      <Slider
        style={styles.slider}
        min={0}
        max={1}
        value={normalizedProgress}
        onSeek={handleSeek}
        onBeginSlide={handleBeginSlide}
        onSlide={handleSlide}
      />
      <AnimatedText
        style={[styles.progressText, styles.rightText]}
        text={progressTextRight}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  bar: {
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
  },
  progressText: {
    color: Color.WHITE,
    width: MAX_TIME_TEXT_WIDTH,
    padding: Spacing.NONE,
  },
  leftText: {
    textAlign: "left",
  },
  rightText: {
    textAlign: "right",
  },
  slider: {
    flexGrow: 1,
  },
});
