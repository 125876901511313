export function millisecondsToMinutesAndSeconds(ms: number) {
  "worklet";
  let remainingMs = ms;
  const minutes = Math.floor(remainingMs / 1000 / 60);
  remainingMs -= minutes * 60 * 1000;
  const seconds = Math.floor(remainingMs / 1000);
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
}

export function millisecondsToSecondsAndCentiseconds(
  ms: number | undefined = 0,
) {
  "worklet";
  const centiSeconds = (ms % 1000).toString().substring(0, 2).padStart(2, "0");

  const seconds = Math.floor((ms / 1000) % 60)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((ms / (60 * 1000)) % 60)
    .toString()
    .padStart(2, "0");

  return `${minutes}:${seconds}.${centiSeconds}`;
}
