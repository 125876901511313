import { useCallback, useEffect, useState } from "react";

import { useAddCallback } from "./useAddCallback";

export function useInputDefaultValueBehavior(
  defaultValue: string,
  autoFocus?: boolean,
) {
  const [value, setValue] = useState(defaultValue);
  const [isEditing, setEditing] = useState(autoFocus);

  useEffect(() => {
    if (isEditing) {
      return;
    }
    setValue(defaultValue);
  }, [defaultValue, isEditing]);

  const setValueProxy = useAddCallback(
    setValue,
    useCallback(() => {
      setEditing(true);
    }, []),
  );

  const overloadedOnBlur = useCallback(() => {
    setEditing(false);
  }, []);

  return [
    value,
    setValueProxy,
    {
      onBlur: overloadedOnBlur,
      autoFocus,
    },
  ] as const;
}
