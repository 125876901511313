import { useCallback } from "react";

import type { AnyFunction } from "@kraaft/helper-types";

export function useAddCallback<T extends AnyFunction>(
  fn: T | undefined,
  remanentFunction?: (...args: Parameters<T>) => void,
) {
  return useCallback(
    (...args: Parameters<T>) => {
      remanentFunction?.(...args);
      return fn?.(...args);
    },
    [fn, remanentFunction],
  );
}
