import { Context } from "react";

import { MeshContext } from "@kraaft/helper-hooks";

import { AbsoluteFillPortal } from "./portal";

interface PortalConfig {
  portalHostname?: string;
  portalForwardContext?: Array<Context<any>>;
  portalForwardMeshContext?: Array<MeshContext<any>>;
}

export function withPortal<P extends PortalConfig>(
  Component: React.ComponentType<P>,
): (props: P) => JSX.Element;
export function withPortal<P extends PortalConfig>(
  Component: React.ComponentType<P>,
  portalConfig: PortalConfig,
): (props: P) => JSX.Element;
export function withPortal<P extends PortalConfig>(
  Component: React.ComponentType<P>,
  portalConfig?: PortalConfig,
): (props: P) => JSX.Element {
  return (props: P) => {
    const config = portalConfig ?? props;

    return (
      <AbsoluteFillPortal
        hostname={config.portalHostname}
        forwardContext={config.portalForwardContext}
        forwardMeshContext={config.portalForwardMeshContext}
      >
        <Component {...props} />
      </AbsoluteFillPortal>
    );
  };
}
