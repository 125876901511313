import React from "react";
import {
  ActivityIndicator,
  type ActivityIndicatorProps,
  StyleSheet,
  View,
} from "react-native";

import { Color, ColorStyle } from "@kraaft/ui";

import { styles } from "./preloader.styles";

interface Props extends ActivityIndicatorProps {
  absoluteFill?: boolean;
  backgroundColor?: string;
  transparent?: boolean;
}

export const Preloader = React.memo((props: Props) => {
  const {
    size = "large",
    color = ColorStyle.ACTION_CTA,
    backgroundColor,
    absoluteFill,
    transparent,
    style,
    ...indicatorProps
  } = props;

  const overlayColor =
    absoluteFill && !transparent ? "rgba(0, 0, 0, 0.5)" : Color.TRANSPARENT;
  const mainStyle = [
    absoluteFill && StyleSheet.absoluteFill,
    styles.main,
    { backgroundColor: backgroundColor || overlayColor },
  ].filter(Boolean);
  const indicatorStyle = [
    style,
    absoluteFill && StyleSheet.absoluteFill,
  ].filter(Boolean);

  return (
    <View style={mainStyle} pointerEvents="none">
      <ActivityIndicator
        size={size}
        color={color}
        style={indicatorStyle}
        {...indicatorProps}
      />
    </View>
  );
});
