import type { KeyOfEnum } from "@kraaft/helper-types";

export const IconSize = {
  MINI: 16,
  SMALL: 18,
  MEDIUM: 24,
  LARGE: 32,
  XLARGE: 40,
  JUMBO: 50,
} as const;

export type IconSize = KeyOfEnum<typeof IconSize>;
