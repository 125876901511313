export const i18nResources = {
  "en-GB": {
    load: async () =>
      import("@kraaft/shared/core/services/i18next/locales/en-GB.json"),
  },
  "en-US": {
    load: async () =>
      import("@kraaft/shared/core/services/i18next/locales/en-GB.json"),
  },
  "fr-FR": {
    load: async () =>
      import("@kraaft/shared/core/services/i18next/locales/fr-FR.json"),
  },
  "it-IT": {
    load: async () =>
      import("@kraaft/shared/core/services/i18next/locales/it-IT.json"),
  },
  "de-DE": {
    load: async () =>
      import("@kraaft/shared/core/services/i18next/locales/de-DE.json"),
  },
  "es-ES": {
    load: async () =>
      import("@kraaft/shared/core/services/i18next/locales/es-ES.json"),
  },
} as const;

export const availableLanguages = Object.keys(
  i18nResources,
) as (keyof typeof i18nResources)[];
