import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  infoContainer: {
    marginTop: "10%",
    maxWidth: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  IETitleText: {
    fontFamily: "Apercu",
    fontSize: 40,
    marginBottom: Spacing.S32,
  },
  IEMessageText: {
    fontFamily: "Apercu",
    fontSize: 25,
    marginBottom: Spacing.S16,
  },
  logoContainer: {
    marginBottom: Spacing.S16,
  },
});
