import {
  forwardRef,
  type PropsWithChildren,
  useImperativeHandle,
  useRef,
} from "react";
import { View } from "react-native";

import { ContextMenus } from "./contextMenus";
import { type UseLocalActionContextMenuProps } from "./localActionContextMenu";

type ContextMenuViewProps = PropsWithChildren<
  Omit<UseLocalActionContextMenuProps, "contextAnchor">
>;

export type ContextMenuViewHandle = {
  open: () => void;
};

/**
 * A React component that provides a context menu view.
 *
 * @example
 * <ContextMenuView data={[{label: "toto", onClick: () => console.log("toto")}]}>
 *   <YourComponent />
 * </ContextMenuView>
 */
export const ContextMenuView = forwardRef<
  ContextMenuViewHandle,
  ContextMenuViewProps
>((props, ref) => {
  const viewRef = useRef(null);

  const { ActionContextMenu, open } = ContextMenus.useLocal({
    contextAnchor: viewRef,
    data: props.data,
  });

  useImperativeHandle(ref, () => ({
    open: open,
  }));

  return (
    <View ref={viewRef}>
      {props.children}
      <ActionContextMenu />
    </View>
  );
});
