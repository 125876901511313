import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { Spacing } from "../../../constants/spacing";
import type { AnchoredSheetDefinition } from "./anchoredSheet.definition/anchoredSheet.definition.types";

export const AnchoredSheetGrowingContent: AnchoredSheetDefinition["GrowingContent"] =
  ({ children, maxHeight, height, noPadding }) => {
    const classes = useStyles();

    return (
      <div
        className={clsx(
          classes.container,
          !noPadding && classes.paddedContainer,
        )}
        style={{ maxHeight, height }}
      >
        {children}
      </div>
    );
  };

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  paddedContainer: {
    padding: `0 ${Spacing.S16}px`,
  },
});
