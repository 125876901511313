export function opacify(hexColor: string, opacity: number) {
  if (!hexColor.startsWith("#")) {
    return hexColor;
  }
  const newOpacity = opacity < 0 ? 0 : opacity > 1 ? 1 : opacity;
  const f = Number.parseInt(hexColor.slice(1), 16);
  const redValue = f >> 16;
  const greenValue = (f >> 8) & 0x00ff;
  const blueValue = f & 0x0000ff;

  function processValue(value: number) {
    return Math.floor(255 - newOpacity * (255 - value));
  }
  return `#${processValue(redValue).toString(16)}${processValue(
    greenValue,
  ).toString(16)}${processValue(blueValue).toString(16)}`;
}

export function alphaHex(hexColor: string, alpha: number) {
  let hexColorValue = hexColor;
  if (hexColorValue.length > 7) {
    console.warn("You are trying to alpha a color already containing an alpha");
    hexColorValue = hexColorValue.slice(0, -2);
  }

  if (hexColorValue.length < 6) {
    console.warn(
      "You passed a 3 characters length color, this is not supported",
    );
  }

  const hexAlpha = Math.floor(alpha * 255)
    .toString(16)
    .padStart(2, "0");

  return `${hexColorValue}${hexAlpha}`;
}
