import { StyleSheet, View } from "react-native";

import { Spacing } from "../../../constants";
import type { PopupSheetDefinition } from "./popupSheet.definition/popupSheet.definition.types";

export const PopupSheetFooter: PopupSheetDefinition["Footer"] = ({
  children,
}) => {
  return <View style={styles.container}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingHorizontal: Spacing.S16,
    gap: Spacing.S16,
  },
});
