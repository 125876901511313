import { StyleSheet } from "react-native";

import { Text } from "../text";
import type { TextProps } from "../text/text.types";
import { type FontIconName, getFontIcon } from "./getFontIcon";

interface IconAsTextProps extends TextProps {
  iconName: FontIconName;
}

export const IconAsText = ({
  iconName,
  style,
  ...textProps
}: IconAsTextProps) => {
  return (
    <Text {...textProps} style={[style, styles.icon]}>
      {getFontIcon(iconName)}
    </Text>
  );
};

const styles = StyleSheet.create({
  icon: {
    fontFamily: "icomoon",
  },
});
