import { LabelWithIcon, type LabelWithIconProps } from "../../display";
import { BaseActionCard, type BaseActionCardProps } from "./baseActionCard";

type LabelledActionCardProps = BaseActionCardProps & LabelWithIconProps;

export const LabelledActionCard = ({
  iconName,
  label,
  color = "FONT_HIGH_EMPHASIS",
  numberOfLines,
  selectable,
  ...baseActionCardProps
}: LabelledActionCardProps) => {
  return (
    <BaseActionCard
      accessibilityLabel={baseActionCardProps.accessibilityLabel}
      id={baseActionCardProps.id}
      onPress={baseActionCardProps.onPress}
      disabled={baseActionCardProps.disabled}
      hideChevron={baseActionCardProps.hideChevron}
    >
      <LabelWithIcon
        iconName={iconName}
        label={label}
        color={color}
        numberOfLines={numberOfLines}
        selectable={selectable}
      />
    </BaseActionCard>
  );
};
