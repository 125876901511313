import type { CSSProperties } from "react";
import {
  type ImageStyle,
  Platform,
  type StyleSheet,
  type TextStyle,
  type ViewStyle,
} from "react-native";

import { omit, pick } from "@kraaft/helper-functions";
import type { DistributedKeyOf } from "@kraaft/helper-types";

export function pickFromStyle<S extends ViewStyle | TextStyle | ImageStyle>(
  style: S,
  keys: DistributedKeyOf<S>[],
) {
  return pick(style, keys) as S;
}

export function omitFromStyle<S extends ViewStyle | TextStyle | ImageStyle>(
  style: S,
  keys: DistributedKeyOf<S>[],
) {
  return omit(style, keys);
}

export function webOnlyStyle(style: CSSProperties) {
  return Platform.select({
    web: style,
  }) as Record<string, unknown> | undefined;
}

export const ensureValidStyleSheet = <T>(styleSheet: T) =>
  styleSheet as StyleSheet.NamedStyles<T>;

export const webHighlightOnFocus = {
  "data-focusable": true,
} as any;
