/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";

import { isDev } from "@kraaft/shared/core/utils/utils";

const ACTIVATE_WHY_DID_YOU_RENDER = false;

if (isDev && ACTIVATE_WHY_DID_YOU_RENDER) {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");

  const ReactRedux = require("react-redux");
  whyDidYouRender(React, {
    collapseGroups: false,
    trackAllPureComponents: true,
    trackHooks: true,
    logOnDifferentValues: true,
    trackExtraHooks: [[ReactRedux, "useSelector"]],
  });
}
