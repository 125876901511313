import type { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";

import { Portal, type PortalProps } from "./portal";

export const AbsoluteFillPortal = ({
  children,
  zIndex,
  ...other
}: PropsWithChildren<PortalProps> & { zIndex?: number | undefined }) => {
  return (
    <Portal {...other}>
      <View
        style={[StyleSheet.absoluteFillObject, { zIndex }]}
        pointerEvents="box-none"
      >
        {children}
      </View>
    </Portal>
  );
};
