import type { PropsWithChildren } from "react";
import { type StyleProp, View, type ViewStyle } from "react-native";

import { useSafeAreaInsets } from "../utils/hooks/useSafeAreaInsets";

interface PaddedSafeAreaViewProps {
  bottomPadding: number;
  style?: StyleProp<ViewStyle>;
}

export const PaddedSafeAreaView = ({
  children,
  bottomPadding,
  style,
}: PropsWithChildren<PaddedSafeAreaViewProps>) => {
  const { top, bottom } = useSafeAreaInsets();

  return (
    <View
      style={[
        style,
        { paddingTop: top, paddingBottom: Math.min(bottom, bottomPadding) },
      ]}
    >
      {children}
    </View>
  );
};
