import { RefObject, useCallback, useEffect } from "react";
import { View } from "react-native";

import { useBooleanState } from "../useBooleanState";

export function useHover(
  ref: RefObject<View | Element>,
  onHover?: (state: boolean) => void,
) {
  const [hovered, setHovered, setLeftHovered] = useBooleanState();

  const handleHoverIn = useCallback(() => {
    onHover?.(true);
    setHovered();
  }, [onHover, setHovered]);

  const handleHoverOut = useCallback(() => {
    onHover?.(false);
    setLeftHovered();
  }, [onHover, setLeftHovered]);

  useEffect(() => {
    const htmlElement = ref.current as HTMLElement | null;

    if (!htmlElement) {
      return;
    }

    htmlElement.addEventListener("mouseenter", handleHoverIn);
    htmlElement.addEventListener("mouseleave", handleHoverOut);
    return () => {
      htmlElement.removeEventListener("mouseenter", handleHoverIn);
      htmlElement.removeEventListener("mouseleave", handleHoverOut);
    };
  });

  return hovered;
}
