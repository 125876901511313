import type React from "react";
import type { ReactNode } from "react";
import { StyleProp, ViewStyle } from "react-native";

import {
  createMeshContext,
  type MeshContext,
  useMeshContext,
} from "@kraaft/helper-hooks";

import { CarouselUtils } from "./carousel.utils";

export interface CarouselPageProps {
  children: ReactNode;
  index: number;
}

export interface CarouselPlaceProps {
  children: ReactNode;
  forwardMeshContext?: Array<MeshContext<any>>;
  index: number;
  style?: StyleProp<ViewStyle> | undefined;
}

export interface CarouselContext {
  focused: number;
  displayUi: boolean;
  Title: React.ComponentType<CarouselPlaceProps>;
  Caption: React.ComponentType<CarouselPlaceProps>;
  Tool: React.ComponentType<CarouselPlaceProps>;
  Bottom: React.ComponentType<CarouselPlaceProps>;
  Page: React.ComponentType<CarouselPageProps>;
}

export const CarouselContext = createMeshContext<CarouselContext>();

export function useCarousel() {
  return useMeshContext(CarouselContext);
}

export function useCarouselShouldRender(index: number) {
  const shouldRender = useMeshContext(CarouselContext, ({ focused }) =>
    CarouselUtils.shouldRender(index, focused),
  );

  return shouldRender;
}

export function useCarouselIsFocused(index: number) {
  return useMeshContext(CarouselContext, ({ focused }) => focused === index);
}
