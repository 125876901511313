import { cloneElement, useRef } from "react";

import { useHover } from "@kraaft/helper-hooks";

import { SheetTooltipProps } from "./sheetTooltip.props";
import { TooltipSheet } from "./tooltip.sheet";

export const SheetTooltip = ({ children, content }: SheetTooltipProps) => {
  const anchor = useRef<HTMLElement>(null);

  const isHovered = useHover(anchor as any);

  const { element } = TooltipSheet.use({
    open: isHovered,
    anchor,
    content,
  });

  return (
    <>
      {cloneElement(children, {
        ref: anchor,
      })}
      {element}
    </>
  );
};
