import "intl-pluralrules";

import { BackendModule } from "i18next";

import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";
import { i18nResources } from "@kraaft/shared/core/services/i18next/i18nextResources";
import { getFallbackLanguage } from "@kraaft/shared/core/services/i18next/i18nextUtils";

const loadedLocales: Record<string, any> = {};

export const LazyLoadingLocales: BackendModule = {
  type: "backend",
  read(language, _, callback) {
    const finalWantedLanguage =
      language && language in i18nResources
        ? (language as PoolLanguage)
        : getFallbackLanguage(language);

    if (language in loadedLocales) {
      return callback(null, loadedLocales[language]);
    }
    i18nResources[finalWantedLanguage]
      .load()
      .then((locale) => {
        loadedLocales[language] = locale;
        callback(null, locale);
      })
      .catch((e) => callback(e, null));
  },
  init() {},
};
