import { useTranslation } from "react-i18next";

import { KraaftLogo } from "@kraaft/shared/components/kraaftLogo";

import { useStyles } from "./ieBanner.styles";

const IEBanner = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const messages = [
    t("internetExplorerMessage"),
    t("browsers"),
    t("internetExplorerContact"),
  ];

  return (
    <div className={classes.container}>
      <div className={classes.infoContainer}>
        <div className={classes.logoContainer}>
          <KraaftLogo />
        </div>
        <span className={classes.IETitleText}>
          {t("internetExplorerTitle")}
        </span>
        {messages.map((message) => (
          <span className={classes.IEMessageText}>{message}</span>
        ))}
      </div>
    </div>
  );
};

export { IEBanner };
