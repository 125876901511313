import { uniq } from "lodash/fp";

import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";
import { availableLanguages } from "@kraaft/shared/core/services/i18next/i18nextResources";

export const DEFAULT_FALLBACK = "en-GB";

export const getFallbackLanguage = (code?: string): PoolLanguage => {
  if (!code) {
    return DEFAULT_FALLBACK;
  }

  const foundLng = findClosestLanguage(availableLanguages, code);

  return foundLng ?? DEFAULT_FALLBACK;
};

export const getFallbackLanguages = (
  code?: string,
): PoolLanguage | Record<string, PoolLanguage[]> => {
  if (!code) {
    return DEFAULT_FALLBACK;
  }

  const fallbackLanguage = getFallbackLanguage(code);

  return {
    [code]: uniq([fallbackLanguage, DEFAULT_FALLBACK]),
  };
};

export const findClosestLanguage = (
  availableCodes: PoolLanguage[],
  code: string,
): PoolLanguage | undefined => {
  if ((availableCodes as string[]).includes(code)) {
    return code as PoolLanguage;
  }

  return availableCodes.find(
    (available) =>
      available.toLowerCase().split("-")[0] ===
      code.toLowerCase().split("-")[0],
  );
};
