import { makeStyles } from "@mui/styles";

import { alpha } from "@kraaft/shared/core/utils/colorsUtils";

import { Color, ColorStyle, Radius, Spacing } from "../../../constants";
import type { PopupSheetDefinition } from "./popupSheet.definition/popupSheet.definition.types";

export const PopupSheetPaper: PopupSheetDefinition["Paper"] = ({
  children,
  maxHeight,
  width,
}) => {
  const classes = useStyles();

  const cssMaxHeight = maxHeight
    ? `min(calc(100vh - ${Spacing.S16 * 2}px), ${maxHeight}px)`
    : `calc(100vh - ${Spacing.S16 * 2}px)`;

  return (
    <div
      className={classes.container}
      style={{ maxHeight: cssMaxHeight, width }}
    >
      {children}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
    padding: `${Spacing.S16}px 0`,
    gap: Spacing.S16,
    borderRadius: Radius.MEDIUM,
    boxShadow: `0px 0px 5px ${alpha(Color.BLACK, 0.25)}`,
    overflow: "auto",
    boxSizing: "border-box",
  },
});
