import { makeStyles } from "@mui/styles";
import { clsx } from "clsx";
import React, { useCallback } from "react";

import { useMeshContext } from "@kraaft/helper-hooks";
import {
  ActionSheetItemProps,
  Color,
  ColorStyle,
  IconSize,
  Preloader,
  Spacing,
} from "@kraaft/ui";

import { Icon, Text } from "../../display";
import { ActionSheetContext } from "./actionSheet.context";

export const ActionSheetItem = React.forwardRef<
  HTMLButtonElement,
  ActionSheetItemProps
>(
  (
    { onClick, icon, label, destructive, loading, preventAutoClose },
    forwardedRef,
  ) => {
    const classes = useStyles();

    const color = loading
      ? ColorStyle.FONT_LOW_EMPHASIS
      : destructive
        ? ColorStyle.ACTION_DESCTRUCTIVE
        : ColorStyle.FONT_HIGH_EMPHASIS;

    const { onClose } = useMeshContext(ActionSheetContext);

    const handleClick = useCallback(() => {
      if (!preventAutoClose) {
        onClose();
      }
      onClick();
    }, [onClick, onClose, preventAutoClose]);

    return (
      <button
        aria-label={label}
        onClick={handleClick}
        className={clsx(classes.buttonContainer)}
        disabled={loading}
        type={"button"}
        ref={forwardedRef}
      >
        <div className={clsx(classes.buttonContent)}>
          {icon && <Icon name={icon} size="MEDIUM" color={color} />}
          <Text size="MEDIUM" color={color} weight={"regular"}>
            {label}
          </Text>
        </div>
        <div className={clsx(classes.loaderContainer)}>
          {loading ? (
            <Preloader
              backgroundColor={Color.TRANSPARENT}
              absoluteFill
              color={color}
              size={IconSize.SMALL}
            />
          ) : null}
        </div>
      </button>
    );
  },
);

const useStyles = makeStyles({
  buttonContainer: {
    display: "inline-flex" as const,
    border: 0,
    outline: 0,
    position: "relative" as const,
    userSelect: "none" as const,
    textAlignVertical: "center" as const,
    textDecoration: "none" as const,

    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S16,
    paddingVertical: Spacing.S8,
    paddingLeft: Spacing.S16,
    // Asked by valentin because of the little offset caused by the icon (the icon is not in the center of its box)
    paddingRight: Spacing.S16 + 4,
    minHeight: Spacing.S40,
    justifyContent: "space-between",
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
    "&:not([disabled])": {
      cursor: "pointer" as const,
    },
    "&:not([disabled]):hover": {
      backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    },
    "&:not([disabled]):active": {
      opacity: 0.5,
    },
  },
  buttonContent: {
    display: "inline-flex" as const,
    textAlignVertical: "center" as const,
    textDecoration: "none" as const,
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
  },
  loaderContainer: {
    width: IconSize.SMALL,
    height: "100%",
    position: "relative",
  },
});
